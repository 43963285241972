<template>
  <div class="goodNews">
    <HeadView classA="3" headBg="1"></HeadView>
    <div class="main">
      <h2>第四届“朗致杯”健康跑，公益开跑</h2>
      <p class="time">2019-11-9</p>
      <p>
        <span
          >2019年11月9日，为践行“强基层，为天使插上翅在膀”的企业使命，致医健康同事们踊跃参与了在南海子公园举行的PEAK+U团队赛暨第四届朗致杯健康长跑活动，该活动由北京经济技术开发区总工会、朗致集团和匹克体育联合主办，汇聚来自北京经济技术开发区的100多家知名企业的千余名跑步爱好者。此次活动以“奔跑吧，我的国！”为主题，以7*10KM健康长跑，献礼祖国70周年华诞。</span
        >
      </p>
      <img src="../../assets/images/newsDetail/healthRun/con2.png" alt />
      <p>
        <span
          >经开区总工会融媒体中心副主任高宁在致辞中表示：“北京经济技术开发区始终保持高质量快速发展，离不开区内企业和员工的支持和奉献，我们发起团队赛和健康跑活动，就是为了鼓励大家在努力工作的同时，通过跑步活动，增强员工的集体荣誉感，保持健康的体魄，将更高、更强、更快的体育精神融入到工作和生活中去。”致医健康总经理李俊峰表示，朗致集团持续发展和推广适宜国人健康的药物。跑步等耐力性运动能够显著提高健康水平，让身体充满活力，让生活呈现蓬勃生机。</span
        >
      </p>
      <p>
        <span
          >最终，选手彭涛以37分31秒赢得此次健康长跑的冠军。坚持公益事业，传递健康精神是本次健康长跑的核心精神。本次健康长跑活动的举办，成功向社会各界传递朗致集团致力于健康事业的讯息，呼吁更多的人享受更健康的生活方式。</span
        >
      </p>
    </div>
    <Foot></Foot>
  </div>
</template>
<style scoped>
@import "../../assets/style/goodNews.css";
</style>
<script>
import Foot from "@/components/common/Foot";
import HeadView from "@/components/common/Head";

export default {
  name: "news",
  components: {
    HeadView,
    Foot,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
